import { useState, useEffect } from 'react';

import Geocode from "../../outils/geocode";

import '../../css/regionFiltre.css';
import ModalFilter from './ModalFilter';
import Title from '../Utils/Title';

import { Grid, InputAdornment, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import TuneIcon from '@mui/icons-material/Tune';
import { PelButton, PelSlider, PelTextField } from '../../themes/@pel/components';

const FilterRegion = ({
    t,
    titre,
    filterParent,
    setFilterParent,
    filterOption,
    deleteFilter
 }) => {

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY);

    const [show, setShow] = useState(false);
    const showModal = () => {
        setShow(true);
    }
    const hideModal = () => {
        setShow(false);
    }

    // Filter
    const [filter, setFilter] = useState(filterParent);

    useEffect(() => {
        setFilter(filterParent);
    }, [filterParent])
    
    
    // Zip Code
    function handlePosition() {
        var lat = "";
        var lng = "";
        navigator.geolocation.getCurrentPosition(function (position) {
            lat = position.coords.latitude.toString();
            lng = position.coords.longitude.toString();

            // Latitude and Longitude to Address
            Geocode.fromLatLng(lat, lng).then(
                (response) => {
                    let address = response.results[0].address_components[8].long_name
                    setFilter({ ...filter, address });
                },
                (error) => {
                    console.error(error);
                }
            );
        });
    }
    const fetchCoordinatesFromAddress = async (address) => {
        const response = await Geocode.fromAddress(address);
        const { lat, lng } = response.results[0].geometry.location;
        return { lat, lng };
    };
 
    // Slider
    function showDistance(value) {
        return  value + " km"
    }

    const  applyFromModal = async (modalData) => {

        const toApply = modalData;
        toApply.inputSearch = filter.inputSearch;
        toApply.address = filter.address;
        if (filter.address) {
            if(filter.localisation.prevAdress != filter.address){
                try {
                const { lat, lng } = await fetchCoordinatesFromAddress(filter.address);
                filter.localisation.prevAdress = filter.address;
                filter.localisation.latitude = lat.toString() ;
                filter.localisation.longitude = lng.toString() ;
                } catch (error) {
                console.error(error);
                }
            }
          } else {
            filter.localisation.latitude = '';
            filter.localisation.longitude = '';
            filter.localisation.prevAdress ='';
          }
        toApply.localisation = filter.localisation;
        setFilter(toApply);
        setFilterParent(toApply);
    }
    return (
        <div className="filterRegion">

            <Title niveau="4" libelle={titre} />

            <Grid container spacing={1}>

                <Grid item xs={12} sm={8} lg={6}>
                    <PelTextField
                        id="outlined-search"
                        label={t("search.search_description")}
                        variant="outlined"
                        value={filter.inputSearch}
                        onChange={(evt) => {
                            const inputSearch = evt.target.value;
                            setFilter({ ...filter, inputSearch });
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                applyFromModal(filter);
                            }
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    
                    <PelTextField
                        type="text"
                        id="outlined-zipcode"
                        label={t("search.position")}
                        value={filter.address}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: "pointer" }} title={t("search.locateMe")}>
                                    <RoomIcon onClick={() => handlePosition()} />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        onChange={(evt) => {
                            const address = evt.target.value;
                            setFilter({ ...filter, address});
                        }}
                        fullWidth
                    />
                    {
                        filter.address &&
                        <>
                            <Typography id="track-false-range-slider" gutterBottom>
                                {t("search.slider") + ": " + showDistance(filter.localisation.distance)}
                            </Typography>
                            <PelSlider
                                defaultValue={20}
                                value={filter.localisation.distance}
                                min={1}
                                max={800}
                                step={1}
                                valueLabelDisplay="auto"
                                onChange={(evt) => {
                                    const inputRadius = evt.target.value;
                                    setFilter({ ...filter, localisation: { ...filter.localisation, distance: inputRadius } });
                                }}
                            />
                        </>
                    }

                </Grid>

                <ModalFilter
                    show={show}
                    t={t}
                    handleClose={hideModal}
                    filterParent={filterParent}
                    setFilterParent={applyFromModal}
                    filterOption={filterOption} />

                <Grid item xs={12} sm={3} lg={1.5}>
                    <PelButton
                        variant="contained"
                        onClick={() => { applyFromModal(filter); }}
                        fullWidth>
                        {t("search.search")}
                    </PelButton>
                </Grid>
                <Grid item xs={12} sm={3} lg={1.5}>
                    <PelButton type="secondary" variant="outlined" startIcon={<TuneIcon />} onClick={showModal} fullWidth>
                        {t("search.filters")}
                    </PelButton>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} 
                  style={{ display: "flex", justifyContent: "flex-end" }}>
                
                    <a href="#" onClick={() => deleteFilter()} className="linkDeleteFilter">{t("home.deleteFilter")}</a>
                </Grid>
            </Grid>
        </div>);
}
export default FilterRegion;