import React, { Fragment, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';

import Title from '../Utils/Title';


import { useTranslation } from "react-i18next";

import { SvgIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import RoomIcon from '@mui/icons-material/Room';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
function Accessibility() {
    const { t, i18n } = useTranslation('common');
    
    return (
        <Fragment>
            <Header />

            <div id="main">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 ">
                                <Title niveau="1" libelle={t('info.accessibility.title')} />
                                <div dangerouslySetInnerHTML={{ __html: t('info.accessibility.detail') }} ></div>
                                <p><SvgIcon component={RoomIcon} inheritViewBox /> {t('info.accessibility.icons.localisation')}</p>    
                                <p><SvgIcon component={CheckIcon} inheritViewBox />      {t('info.accessibility.icons.valide')}    </p>
                                <p><SvgIcon component={BorderColorIcon} inheritViewBox />     {t('info.accessibility.icons.modify')}    </p>    
                                <p><SvgIcon component={DeleteOutlineIcon} inheritViewBox />      {t('info.accessibility.icons.trash')}    </p>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </Fragment>
    );

}
export default Accessibility;