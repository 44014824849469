import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

const PelIcon = styled('span')(({ theme }) => ({
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    border: "1px solid" + theme.palette.checkbox.active,
    'input:hover ~ &': {
        border: "2px solid" + theme.palette.checkbox.pressed,
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        border: "1px solid" + theme.palette.checkbox.disabled,
    },
}));

const PelCheckedIcon = styled(PelIcon)(({ theme }) => ({
    backgroundColor: theme.palette.checkbox.checked,
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
        margin: "-1px"
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
}));

export const PelCheckBox = (props) => {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<PelCheckedIcon />}
            icon={<PelIcon />}
            {...props}
        />
    );
}

