import React, {useEffect} from "react";
import { Routes, Route, useNavigate ,useSearchParams  } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Home from "./Home";
import JoinUs from "./Info/JoinUs";
import Accessibility from "./Info/Accessibility";
import PrivacyPolicy from "./Info/PrivacyPolicy";
import TermsOfUse from "./Info/TermsOfUse";
import PostDetail from "./PostDetail/PostDetail";

const RoutesApp = (props) => {
    const {t, i18n} = useTranslation('common');
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    //Gestion langue
    useEffect(() => {
        if (searchParams.has("lang")) {
            let lang = searchParams.get("lang");
            lang = lang.trim().substring(0, 2).toLowerCase();
            if(lang=='fr'||lang=='en'){
                i18n.changeLanguage(lang);
            }
            searchParams.delete("lang");
            setSearchParams(searchParams);
        }
      }, []);
    
    //Recherche poste    
    useEffect(() => {
        if (searchParams.has("ide_poste")) {
            const ide_poste = searchParams.get("ide_poste");

            searchParams.delete("ide_poste");
            setSearchParams(searchParams);

            navigate('/poste/'+ide_poste);
        }
      }, []);
    return (
        <>
            <Routes>

                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/poste/:postingIdParam"
                    element={<PostDetail />}
                />
                <Route
                    path="/nous-joindre"
                    element={<JoinUs />}
                />
                <Route
                    path="/contact-us"
                    element={<JoinUs />}
                />
                <Route
                    path="/accessibilite"
                    element={<Accessibility />}
                />
                <Route
                    path="/accessibility"
                    element={<Accessibility />}
                />
                <Route
                    path="/politique-de-confidentialite"
                    element={<PrivacyPolicy />}
                />
                <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}
                />
                <Route
                    path="/terms-of-use"
                    element={<TermsOfUse />}
                />
                <Route
                    path="/conditions-utilisations"
                    element={<TermsOfUse />}
                />
            </Routes>
        </>
    )
}

export default RoutesApp;