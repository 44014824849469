import React, { Fragment } from 'react';
import '../css/footer.css';
import logoMTESS from '../images/MESS_w3_couleur.png';

import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
const Footer = ()=>{
     const {t, i18n } = useTranslation('common');
     return (<Fragment>
                    <footer role="contentinfo" id="piv_piedPage" className="piv_piedPage bleed">
                        <div className="contenu">
                            <div id="personnaliser">
                                <ul id="navFooter">
                                    <li>
                                        <Link  to={t('footer.accessibiliteURL')}>{t('footer.accessibilite')}</Link>
                                    </li>
                                    <li>
                                        <Link  to={t('footer.politiqueConfidentialiteURL')}>{t('footer.politiqueConfidentialite')}</Link>
                                    </li>
                                    <li>
                                         <Link  to={t('footer.conditionsURL')}>{t('footer.conditions')}</Link>
                                    </li>
                                    {i18n.resolvedLanguage=='en'?
                                        <li className="last">
                                            <a target="_blank" rel="noreferrer" href="https://www.quebec.ca/en/government/policies-orientations/french-language/modernization-charter-french-language#c214034">Who can consult this page</a>
                                        </li>:''}
                                    <li className="last">
                                        <a target="_blank" rel="noreferrer" href={t('footer.propulsesURL')}>{t('footer.propulse')}</a>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div id="logo">
                                <a href="https://quebec.ca" target="_blank" rel="noreferrer">
                                    <img src={logoMTESS} alt="Logo du gouvernement du Québec."/>
                                </a>
                            </div>
                            <div id="credits">
                                <small><a href="http://www.droitauteur.gouv.qc.ca/copyright.php" target="_blank" rel="noreferrer">© Gouvernement du Québec, {new Date().getFullYear()}</a></small>
                            </div>
                        </div>
                    </footer>
                </Fragment>);
        }
    export default Footer;