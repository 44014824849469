import '../../css/title.css';

const Title = ({ niveau, libelle }) => {
    switch (niveau) {
        case "1":
            return <h1>{libelle}</h1>;
        case "2":
            return <h2>{libelle}</h2>;
        case "3":
            return <h3>{libelle}</h3>;
        case "4":
            return <h4>{libelle}</h4>;
        case "5":
            return <h5>{libelle}</h5>;
        case "6":
            return <h6>{libelle}</h6>;
        default:
            break;
    }
}
export default Title;