export {
    getManitouLsUrl,
    getElkBackendSearchUrl,
    getRecommandationsUrl,
    getLibreServiceUrl,
}

function getManitouLsUrl() {
    return process.env.REACT_APP_URL_MANITOU_LS || window.location.origin
}

function getElkBackendSearchUrl() {
    return process.env.REACT_APP_URL_ELK_BACKEND_SEARCH || window.location.origin
}

function getRecommandationsUrl() {
    return process.env.REACT_APP_URL_RECOMMANDATIONS || window.location.origin
}

function getLibreServiceUrl() {
    return process.env.REACT_APP_URL_LIBRESERVICE || window.location.origin
}