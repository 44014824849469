import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const SkeletonPosteDetail = () => {
    return ( 
      <Fragment>
         <div className="row">
            <div className="col-6 col-md-6 ">
               <div className="infoSection">
                  <Skeleton height={25}  count={1} />
                  <Skeleton height={25}  count={1} />
                  <Skeleton height={25} count={1} />
               </div>
            </div>
            <div className="col-6 col-md-6 ">
               <div className="infoSection">
               <Skeleton height={25}  count={1} />
               <Skeleton height={25}  count={1} />
               <Skeleton height={25} count={1} />
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-12 col-md-12 ">
               <Skeleton height={25} count={15} />

            </div>
         </div>
      </Fragment>
    
    );
  };
  export default SkeletonPosteDetail;