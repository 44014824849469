import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

import Header from './Header';
import Footer from './Footer';
import MainMapBlock from './Map/MainMapBlock';

import '../css/listePoste.css';
import FilterRegion from './SearchContainer/FilterRegion';
import Title from './Utils/Title';


import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MapIcon from '@mui/icons-material/Map';
import Select from '@mui/material/Select';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { PelButton } from '../themes/@pel/components';

import { sendEventPosting,  getUserIA } from '../js/OutilRecommandation';

import FilterList from './SearchContainer/FilterList';
import ModalPostDetail from './PostDetail/ModalPostDetail';
import SkeletonListePoste from './skeleton/SkeletonListePoste';
import {
    getManitouLsUrl,
    getElkBackendSearchUrl
} from '../js/utils';
const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const valueToReturn = (<div
                                role="tabpanel"
                                hidden={value !== index}
                                id={`simple-tabpanel-${index}`}
                                aria-labelledby={`simple-tab-${index}`}
                                {...other}
                            >
                                {value === index  &&(
                                    <Box sx={{ p: 3 }}>
                                        {children}
                                    </Box>
                                )}
                            </div>);

    return valueToReturn;
}

function Home() {
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="noOption">{t("modal.noOption")}</span>
            </components.NoOptionsMessage>
        );
    }; 
    const hideModal = () => {
        document.getElementById('root').classList.remove("hiddenPrint");
        const toApply = { ...infoModalDetail, show: false };
        setInfoModalDetail(toApply);
    }
    const [hasError, setHasError] = useState(false);

    const [filterOption, setFilterOption] = useState({    });
    const [existingPost, setExistingPost] = useState(0);
    const [postFound, setPostFound] = useState(undefined);
    const [infoModalDetail,setInfoModalDetail] = useState({show:false,postingId:null,title:null});
    const defaultValueFilter = {
        "sort": {
            "type": "AUTO",
        },
        "langue": i18n.resolvedLanguage,
        "page": 1,
        "identAWS": getUserIA,
        "filter": {
            "inputSearch": "",
            "address":"",
            "localisation": {
                "longitude": "",
                "latitude": "",
                "distance": 20
            },
            "adminRegion": [],
            "offerType": [],
            "commitment": [],
            "jobDuration": [],
            "levelEducation": [],
            "studyDiscipline": [],
            "mrc": [],
            "bsq": [],
            "scian": [],
            "postedSince": "",
            "excludeAgencies": false,
            "isUkrainian": false,
            "isExperimente": false,
            "isSubsidized": false,
            "isTrainingProgram": false
        }
    }
    const [gestionPage, setGestionPage] = useState(defaultValueFilter);

    const setGestionPageFiltre = (filter) => {
        const toApply = { ...gestionPage, filter: filter,page:1 };
        updateGestionPage(toApply);
    }
    const setGestionPageNextPage = () => {
        const NextPage = gestionPage.page+1;
        const toApply = { ...gestionPage, page: NextPage };
        updateGestionPage(toApply);
    }
    function updateGestionPage(toApply) {
        setGestionPage(toApply);
        getPostList(toApply);
    }
    const [pagination, setPagination] = useState({
        data: [],
        offset: 0,
        numberPerPage: 25,
        pageCount: 0,
        currentData: []
    });


    const [listePoint, setListePoint] = useState([]);

 
    useEffect(() => {
            const copy = pagination.data.map((result) => {
                return { ...result, show: false }
            });
            // Group when location is equal
            const results = copy.reduce((result, current) => {
                const key = current.latitude + ";" + current.longitude;
                if (!result[key]) result[key] = [];
                result[key].push(current);
                return result;
            }, {});
            setListePoint(results);

    }, [pagination.data])

  


    const [tabValue, setTabValue] = React.useState(0);

    const [isCriteria, setIsCriteria] = useState(false);

    function checkCriteria() {
        const filter = gestionPage.filter;
        const ignoreTab = ["inputSearch", "address","localisation"];
        for (const value of Object.keys(filter)) {
            if (ignoreTab.includes(value)) continue;
            if (typeof filter[value] === "boolean" && filter[value]) {
                setIsCriteria(true);
                return;
            }
            else if (filter[value].length > 0) {
                setIsCriteria(true);
                return;
            }
        };
        setIsCriteria(false);
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        updateGestionPage({ ...gestionPage,page:1,langue: i18n.resolvedLanguage });
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
    
        gtag('config', 'UA-167288201-1');
    }, [i18n.resolvedLanguage])

    useEffect(() => {
        const getDataFromAPI = () => {
            fetch(getManitouLsUrl() + '/manitouLS/cache/postingDisplayFilters/' + i18n.resolvedLanguage)
                .then((response) => {    
                    if (response.ok) {      
                        return response.json();    
                    }                   
                    throw new Error(`HTTP Response Code: ${response?.status}`);  
                })  
                .then((res) => {
                    setFilterOption(res);
                })
                .catch((err) => {
                    setHasError(true);
                    console.log(err.message);
                });
        }
        getDataFromAPI();
    }, [i18n.resolvedLanguage])


    // Component on mount
    useEffect(() => {
        const getDataFromAPI = () => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            fetch(getElkBackendSearchUrl() + '/search/posting/nbActuel', requestOptions)
                .then((response) => {    
                    if (response.ok) {      
                        return response.json();    
                    }                   
                    throw new Error(`HTTP Response Code: ${response?.status}`);  
                })  
                .then((res) => {
                    setExistingPost(res.nb_found);
                })
                .catch((err) => {
                    setHasError(true);
                    console.log(err.message);
                });
        }


        getDataFromAPI();

        getPostList();
    }, []);

    function deleteFilter() {
        setGestionPage(defaultValueFilter);
        getPostList(defaultValueFilter);
    }

    useEffect(() => {
        checkCriteria();
    }, [gestionPage])

    const [loading, setLoading] = useState(true);
    const [isSmall, setIsSmall] = useState(false);

    // Responsive
    useEffect(() => {
        window.addEventListener("resize", resize.bind(this));
        function resize() {
            setIsSmall(window.innerWidth <= 760);
        }
        resize();
        window.removeEventListener("resize", resize.bind(this));
    }, [])

    const handlePageClick = event => {
        const selected = event.selected;
        const offset = selected * pagination.numberPerPage;
        setPagination({ ...pagination, offset });
        // navigate(`/table/${parseInt(selected) + 1}`);
    }

    useEffect(() => {
        getPostListFromStorage();

    }, [pagination.offset, pagination.numberPerPage])



    function getPostList(toApply = gestionPage) {
        
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(toApply)
        };
        fetch(getElkBackendSearchUrl() + '/search/postingFilteredAI', requestOptions)
            .then((response) => {    
                if (response.ok) {      
                    return response.json();    
                }                   
                throw new Error(`HTTP Response Code: ${response?.status}`);  
            })  
            .then((res) => {
                let result = res.items;
                if(toApply.page!=1){
                    result = [...pagination.data, ...result] ;
                }
                const pageCount = result.length / pagination.numberPerPage;
                const currentData = result.slice(pagination.offset, pagination.offset + pagination.numberPerPage);
                setPagination({ ...pagination, data: result, pageCount, currentData })
                setPostFound(res.meta.total_hits);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
                setHasError(true);
                setLoading(false);
            });
    }

    function getPostListFromStorage() {
        let totalHits = 0;
       
        totalHits = postFound;
        const pageCount = pagination.data.length / pagination.numberPerPage;
        const currentData = pagination.data.slice(pagination.offset, pagination.offset + pagination.numberPerPage);
        setPagination({ ...pagination, data: pagination.data, pageCount, currentData })
        setPostFound(totalHits);
    }


    const handleOnClickConsult = useCallback((ideAffch,titre,mode) => {
        sendEventPosting('POSTING_'+mode+'_DETAIL',ideAffch);
        
        document.getElementById('root').classList.add("hiddenPrint");
        setInfoModalDetail({show:true,postingId:ideAffch,title:titre});
    })


    return (
        <Fragment>

            <Helmet>
                <title>{t('title.currentJob')}  | Québec Emploi</title>
                
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-167288201-1"></script>
                <script>
                {`
                `}
                 </script>


            </Helmet>

            <Header />
            {i18n.resolvedLanguage=='en'?
            <div class="container-fluid alerte-generale messageGeneral" id="bandeau-alerte-anglais" role="alert" aria-label="Important information">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 d-flex flex-row">
                            <p>The <em>Charter of the French language</em> and its regulations govern the <a href="https://www.quebec.ca/en/government/policies-orientations/french-language/modernization-charter-french-language#c214022" target="_BLANK">consultation of English-language content <OpenInNewIcon/></a>.&thinsp;</p>
                        </div>
                    </div>
                </div>
            </div>:''}
            <div id="main">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 ">
                                <Title niveau="1" libelle={t('home.title')} />
                            </div>
                        </div>
                        {(hasError==false?
                        <Fragment>
                            <div className="row">
                                <div className="col-12 col-md-12 ">
                                    <FilterRegion
                                        t={t}
                                        titre={t('home.filterTitle')}
                                        filterOption={filterOption}
                                        filterParent={gestionPage.filter}
                                        setFilterParent={setGestionPageFiltre}
                                        deleteFilter={deleteFilter}
                                    />
                                </div>
                            </div>
                            {
                                isCriteria ?
                                    <div className="row">
                                        <div className="col-12 col-md-12 ">
                                            <FilterList
                                                t={t}
                                                titre={t('home.criteria')}
                                                filterParent={gestionPage.filter}
                                                filterOption={filterOption}
                                                setFilterParent={setGestionPageFiltre}
                                            />
                                        </div>
                                    </div>
                                    : <></>
                            }
                        </Fragment>:'')}
                    </div>
                </div>
            </div>
            {(hasError==false?
            <Fragment>
            <div className="container">

                <div className="row" >

                    <div className="col-md-10" style={{ paddingTop: "20px" }}>
                        {!loading ?
                            <div>
                                <b>
                                    {(postFound < existingPost ? t('home.postInfo.nbFound').replace('%0', numberWithSpaces(postFound)).replace('%1', numberWithSpaces(existingPost)) :
                                        t('home.postInfo.nbTotal').replace('%0', numberWithSpaces(existingPost)))}
                                    <br />
                                </b>
                                {(postFound > pagination.data.length ? t('home.postInfo.nbLimit').replace('%0', numberWithSpaces(pagination.data.length)): '')}
                                {(postFound > pagination.data.length && gestionPage.page<4 ? 
                                <Fragment>{t('home.postInfo.loadMoreBetween')} <a href="#" onClick={() => setGestionPageNextPage()} >
                                    {t('home.postInfo.loadMore')}
                                </a></Fragment>
                                : '')}
                                {(postFound > pagination.data.length ? '.': '')}
                                
                            </div> : ''}
                    </div>

                    <div className="col-md-2" style={{ paddingTop: "20px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="sort-label">{t("home.sort.label")}</InputLabel>
                            <Select
                                name="sort"
                                labelId="sort-label"
                                id="sort"
                                components={{ NoOptionsMessage }}
                                options={t("home.sort.option", { returnObjects: true })}
                                value={gestionPage.sort.type}
                                label={t("home.sort.label")}
                                onChange={(event) => {
                                    const sortVal = (event.target.value);
                                    updateGestionPage({ ...gestionPage, page:1,sort: { "type": sortVal } });
                                }
                                }>
                                {t("home.sort.option", { returnObjects: true }).map((c) => <MenuItem key={"sort" + c.value} value={c.value}>{c.label}</MenuItem>)}
                            </Select>
                        </FormControl >
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row">

                    {
                        existingPost > 0 && postFound === 0 ?

                            <div className="col-sm-12 col-md-12">
                                <Typography variant="h4" >
                                    {t("home.noPostFound")}
                                </Typography>
                            </div>

                            :

                            <div className="col-sm-12 col-md-12">
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab icon={<ListAltIcon />} iconPosition="start" label={t("home.tab.table")} {...a11yProps(0)} />
                                        <Tab icon={<MapIcon />} iconPosition="start" label={t("home.tab.map")} {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tabValue} index={0}>
                                    <div className="table-responsive">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell id="col-numberTitle">{t("table.numberTitle")}</TableCell>
                                                    <TableCell id="col-name" width="20%">{t("table.name")}</TableCell>
                                                    <TableCell id="col-town" width="10%">{t("table.town")}</TableCell>
                                                    <TableCell id="col-date" width="10%">{t("table.date")}</TableCell>
                                                    {gestionPage.filter.localisation.longitude!=''?<TableCell id="col-dist" >{t("table.distance")}</TableCell>:''}
                                                    <TableCell id="col-consult" width="5%"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? <SkeletonListePoste /> : ''}
                                                {!loading && pagination.currentData && pagination.currentData.map(((item, index) => (
                                                    <TableRow
                                                        key={item.ide_affch}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        className="post"
                                                    >
                                                        <TableCell headers="col-numberTitle">
                                                            {`${item.ide_affch} - ${item.titre}`}
                                                        </TableCell>
                                                        <TableCell headers="col-name">{item.employeur}</TableCell>
                                                        <TableCell headers="col-town">{item.nom_ville}</TableCell>
                                                        <TableCell headers="col-date">{item.expiration_date}</TableCell>
                                                        {gestionPage.filter.localisation.longitude!=''?<TableCell headers="col-dist">{Math.round(item.distance[0])}</TableCell>:''}
                                                        <TableCell headers="col-consult">
                                                            <PelButton aria-label={`${item.ide_affch} - ${item.titre}`} type="secondary" variant="outlined" onClick={() => handleOnClickConsult(item.ide_affch,item.titre,'TABLE')}>
                                                                {t("home.consult")}
                                                            </PelButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )))
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div className="pagination-content">
                                        <ReactPaginate
                                            previousLabel={<><ArrowBackIcon /> {!isSmall && t("page.previous")}</>}
                                            nextLabel={<> {!isSmall && t("page.next")}<ArrowForwardIcon /></>}
                                            breakLabel={'...'}
                                            pageCount={pagination.pageCount}
                                            marginPagesDisplayed={isSmall ? 1 : 2}
                                            pageRangeDisplayed={isSmall ? 2 : 5}
                                            onPageChange={handlePageClick}
                                            containerClassName={'pagination'}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            previousAriaLabel= {t("page.previous")}
                                            nextAriaLabel= {t("page.next")}
                                        />
                                        
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <div style={{ height: '100vh', width: '100%' }}>
                                        <MainMapBlock
                                            t={t}
                                            data={pagination.data}
                                            filterParent={gestionPage.filter}
                                            handleOnClickConsult={handleOnClickConsult}
                                            listePoint={listePoint}
                                            setListePoint={setListePoint}
                                        />
                                    </div>
                                </TabPanel>

                            </div>
                    }
                </div>
            </div>
            </Fragment>:
            <div className="container">
                <div className="alert alert-danger" role="alert">
                    {t("home.error")}
                </div>
            </div>)}

            <Footer />
            <ModalPostDetail 
                    t={t}
                    handleClose={hideModal} 
                    show={infoModalDetail.show} 
                    postingId={infoModalDetail.postingId} 
                    postingTitle={infoModalDetail.title}
                    setInfoModalDetail={setInfoModalDetail}/>
        </Fragment>
    );

}
export default Home;