import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useTheme } from '@mui/material/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TitleIcon from '@mui/icons-material/Title';

import Title from '../Utils/Title';
import { PelButton } from '../../themes/@pel/components';

/*****************************
 * Exemple utilisé
 * https://github.com/google-map-react/google-map-react-examples/blob/master/src/examples/MarkerInfoWindow.js
 * ***************************/
// InfoWindow component
const InfoWindow = (props) => {
  const { place, toggleShow,show, t, handleOnClickConsult } = props;
  const infoWindowStyle = {
    position: "absolute",
    left: "10px",
    bottom: "8px",
    transform: "translateX(-50%)",
    maxWidth: "648px",
    width:"100vw",
    maxHeight: "574px",
    overflow: "auto",
    backgroundColor: 'white',
    border: '4px solid #237ec9',
    padding: 10,
    fontSize: 13,
    fontWeight: 300,
    zIndex: 100,
  };
  const arrowStyle = {
    position: 'absolute',
    bottom: 0,
    width: 0,
    height: 0,
    borderLeft: "12px solid transparent",
    borderRight: "12px solid transparent",
    borderTop: "8px solid white",
    color: "white",
    fontSize: 23,
    fontWeight: 300,
    zIndex: 100,
  };
  const iconStyle = {
    transform: "scale(1.8)"
  };

  function renderInfo(place) {
    const townStyle = {
      fontSize: "20px !important",
      fontWeight: "200px !important",
    };

    return (
      <Grid key={place.ide_affch}>
        <Grid item xs={12} align="left">
          <Title niveau="6" libelle={place.ide_affch + " - " + place.titre} />
        </Grid>

        <Grid item xs={12} align="left" className={"padding"}>
          <Typography><TitleIcon /> {place.employeur}</Typography>
          {/* <Typography>{t("map.employer")}: {place.employeur}</Typography> */}
        </Grid>

        <Grid item xs={12} align="left" className={"padding"}>
          <PelButton
            type="secondary"
            variant="outlined"
            onClick={() => handleOnClickConsult(place.ide_affch,place.titre)} >
            {t("Details")}
          </PelButton>
        </Grid>

      </Grid>
    )
  }

  return (
    <div>
      <div style={infoWindowStyle}>

        <Grid container>
          <Grid item xs={11} align="right">
            {
              place.map((res) =>
                renderInfo(res)
              )
            }
          </Grid>
          <Grid item xs={1} align="right">
            <IconButton
              edge="start"
              align="right"
              color="inherit"
              aria-label="Close"
              style={{ padding: 8 }}
              onClick={() => toggleShow(place.ide_affch)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={arrowStyle}>
        {/* <ArrowDropDownIcon style={iconStyle}/> */}
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;



const Marker = ({ t, show, place, toggleShow, handleOnClickConsult, placeArray, mapRef }) => {
  const theme = useTheme();

  const markerStyle = {
    color: theme.palette.pelBlue,
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <BusinessCenterIcon style={markerStyle} onClick={() => {
        mapRef.current.panTo({ lat: place.latitude, lng: place.longitude });
        toggleShow(place.ide_affch);
      }} />
      {show==place.ide_affch &&
        <InfoWindow
          t={t}
          place={placeArray}
          toggleShow={toggleShow}
          handleOnClickConsult={handleOnClickConsult}
        />}
    </>
  );
};

class MarkerInfoWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
    };
  }

  componentDidMount() {
    fetch('places.json')
      .then((response) => {    
          if (response.ok) {      
              return response.json();    
          }                   
          throw new Error(`HTTP Response Code: ${response?.status}`);  
      })  
      .then((data) => {
        data.results.forEach((result) => {
          result.show = false; // eslint-disable-line no-param-reassign
        });
        this.setState({ places: data.results });
      });
  }

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key) => {
    this.setState((state) => {
      const index = state.places.findIndex((e) => e.id === key);
      state.places[index].show = !state.places[index].show; // eslint-disable-line no-param-reassign
      return { places: state.places };
    });
  };

  render() {
    const { places } = this.state;

    return (
      <>
        {!isEmpty(places) && (
          <GoogleMap
            defaultZoom={10}
            defaultCenter={LOS_ANGELES_CENTER}
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
            onChildClick={this.onChildClickCallback}
          >
            {places.map((place) => (
              <Marker
                key={place.id}
                lat={place.geometry.location.lat}
                lng={place.geometry.location.lng}
                show={show}
                place={place}
              />
            ))}
          </GoogleMap>
        )}
      </>
    );
  }
}



Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    employeur: PropTypes.string,
    expiration_date: PropTypes.string,
    ide_affch: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    nom_ville: PropTypes.string,
    show: PropTypes.bool,
    titre: PropTypes.string,
  })
  // .isRequired,
};

export default Marker;