import React, { Fragment } from 'react';



function Info({ post, idSection }) {



    return (
        <Fragment>
            {(post.body ? post.body.map(function (item, i) {
                if (item.id == idSection) {
                    return <div key={item.id} className="info">
                        <label>{item.label}</label>
                        <span dangerouslySetInnerHTML={{ __html: item.content }}></span>
                    </div>
                }
            }) : '')}

        </Fragment>
    );

}
export default Info;