import React, { Fragment, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';

import Title from '../Utils/Title';


import { useTranslation } from "react-i18next";


function PrivacyPolicy() {
    const { t, i18n } = useTranslation('common');
    
    return (
        <Fragment>
            <Header />

            <div id="main">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 ">
                                <Title niveau="1" libelle={t('info.privacyPolicy.title')} />
                                <div dangerouslySetInnerHTML={{ __html: t('info.privacyPolicy.detail') }} ></div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </Fragment>
    );

}
export default PrivacyPolicy;