import React, { Fragment ,useState,useEffect} from 'react';
import logoQC from '../images/QUEBEC_blanc.svg';
import '../css/header.css';

import { SvgIcon } from '@mui/material';
import Alert from '@mui/material/Alert';
import Person from '@mui/icons-material/Person';
import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import {
    getManitouLsUrl,
    getLibreServiceUrl
} from '../js/utils';
  
const Header = ()=>{
     const {t, i18n} = useTranslation('common');
     const [messageMaintenance,setMessageMaintenance]=useState(null);
     useEffect(() => {
        fetch(getManitouLsUrl() + '/manitouLS/cache/checkFutureMaintenancePeriod/' + i18n.resolvedLanguage )
            .then((response) => {    
                if (response.ok) {      
                    return response.json();    
                }                   
                throw new Error(`HTTP Response Code: ${response?.status}`);  
            })  
            .then((res) => {
                setMessageMaintenance(res.period);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [i18n.resolvedLanguage])
     return (<Fragment>
                    <a href="#contenu" className="sauter visuallyhidden focusable">{t('header.goContent')}</a>
                    <header id="piv_entete" className="piv_entete--bleu bleed" role="banner">
                        <div className="container">
                        <div id="logo" className="flex align-items-center">
                            
                                <a href="https://quebec.ca">
                                    <img id="pivImage" src={logoQC} alt={t('header.altLogo')} title="Québec.ca" height="72px"/>
                                </a>
                            </div>
                            <div id="piv_identite" className="desktop">
                                <p className="d-none d-md-block"><Link to="/">{t('header.title')}</Link></p>
                            </div>
                            
                            <div id="piv_menu" className="flex align-items-center justify-content-end">
                        
                                <nav className="menu">
                                    <button className="menu__controle"><span className="menu__libelle">Menu</span></button>
                                    <div className="menu__liste"> 
                                        
                                        <ul className="piv">
                                            <li className="menu__listeItem"><a onClick={() =>{i18n.changeLanguage(t('header.translateToCode'));}} className="menu__lien">{t('header.translateTo')}</a></li>
                                            
                                            <li className="menu__listeItem">
                                                
                                                <Link to={t('header.joinsUsURL')} className="menu__lien">
                                                    {t('header.joinUs')}
                                                </Link>
                                            </li>
                                        </ul> 
                                </div>
                                </nav> 
                            </div>
                            <div id="piv_identite" className="mobile">
                                    <p>{t('header.title')}</p>
                            </div>
                        </div>
                    </header>
                    <div id="sub-piv">
                        <div className="container">
                            <div className="menu">
                                <a href={ getLibreServiceUrl()  + t('header.loginURL')}><span><SvgIcon component={Person} inheritViewBox />{t('header.login')}</span></a>
                            </div>
                        </div>
                    </div>
                    {(messageMaintenance!=null?
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 " style={{marginTop:'10px'}}>
                                <Alert severity="warning" >
                                    <span dangerouslySetInnerHTML={{__html: messageMaintenance}}></span>
                                </Alert>
                                        
                            </div>
                        </div>
                      </div>:'')}
                    
                </Fragment>);
        }
    export default Header;