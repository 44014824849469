import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const SkeletonModalFilter = () => {
    return ( 
      <Fragment>

         <div className="row">
            <div className="col-12 col-md-12 ">
               <Skeleton height={25} count={15} width={750} />

            </div>
         </div>
      </Fragment>
    
    );
  };
  export default SkeletonModalFilter;