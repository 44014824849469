import {
    getRecommandationsUrl
} from './utils';

export {
    sendEventPosting,
    getUserIA
}


var ANONYMOUS_ID_COOKIE_NAME = "MANITOU_ANONYMOUS_UUID";
var USER_ID_COOKIE_NAME = "MANITOU_APPLICANT";
var SUPPORTED_BUTTONS = {
        "POSTING_APPLY": "plateforme-emploi-offre-postuler",
        "POSTING_TABLE_DETAIL": "plateforme-emploi-tableau-detail",
        "POSTING_MAP_DETAIL": "plateforme-emploi-carte-detail",
        "POSTING_RECOMMENDATION_DETAIL": "plateforme-emploi-recommande-detail"
}



function sendEventPosting(buttonID, postingID) {
    if (SUPPORTED_BUTTONS[buttonID]) {

        var payload = {
            anonymous_uuid: getAnonymousUUID(),
            ide_poste: postingID,
            button: SUPPORTED_BUTTONS[buttonID],
            action: "click",
            timestamp: new Date().getTime()
        }

        var userID = getUserID();
        if (userID && userID.trim() != "") {
            payload.user_id = userID;
        }



        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };
        fetch(getRecommandationsUrl() + '/recommandations/event/', requestOptions)
            .then((response) => response.json())
            .then((res) => {
                
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
}
function getUserIA(){
    var v_user = getUserID();
    v_user=(v_user!=''?v_user:getAnonymousUUID());
    return v_user;
}


function getAnonymousUUID() {
    var anonymousUUID = getCookie(ANONYMOUS_ID_COOKIE_NAME);
    if (!anonymousUUID || anonymousUUID.trim() == "") {
        anonymousUUID = generateUniqueID();
        setCookie(ANONYMOUS_ID_COOKIE_NAME, anonymousUUID);
    }
    return anonymousUUID;
}

function getUserID() {
    return getCookie(USER_ID_COOKIE_NAME);
}

function generateUniqueID() {
    function chr4() {
        return Math.random().toString(16).slice(-4);
    }
    var uniqueID =
        chr4() + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();

    return uniqueID
}

function setCookie(cookieName, cookieValue) {
    var expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    document.cookie = cookieName + "=" + cookieValue + "; path=/; secure; expires=" + expirationDate.toUTCString();
};

function getCookie(cookieName) {
    var name = cookieName + "=";
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) == " ") {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
};
