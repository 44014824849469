import React from 'react';



import '../../css/breadcrumb.css';


import { Link } from 'react-router-dom';

function Breadcrumb({data}) {
   
    return (
                <div className="container-fluid">
                    <div className="container fil-ariane-container">
                        <ol className="filAriane ellipse">
                             {data.map(((item, index) => (
                                (item.link? <li key={index}><Link to={item.link} >{item.title}</Link>&nbsp;&nbsp;</li>:<li className="curitem" key={index}>{item.title}</li>)                                
                             
                            )))}
                            
                            </ol>
                    </div>
                </div>
    );

}
export default Breadcrumb;