import React from 'react';

import Title from '../Utils/Title';
import '../../css/modal.css';

import { Helmet } from 'react-helmet';

import { Dialog, DialogContent, Grid, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PostDetail from './PostDetail';

import logoQC from '../../images/QUEBEC_couleur.svg';

const ModalPostDetail = ({ t,
  handleClose,
  show,
  postingId,
  postingTitle,
  setInfoModalDetail}) => {
  return (

    (
      <Dialog
        className={"root"}
        maxWidth="lg"
        open={show}
        onClose={() => handleClose()}
        style={{ padding: '0px 0px 0px 0px' }}
      >
        <DialogContent className={"paddingModal"}>
          <Grid container spacing={1}>
            <Grid item xs={11} align="left" className={"padding"}>
              
                <div className="print-section">
                      <img src={logoQC} alt="Logo du gouvernement du Québec." height="50px"/>
                      <span>{t('header.title')}</span>
                </div>
              <Title niveau="1" libelle={postingId+ ' - '+ postingTitle } />
              
               <Helmet>
                    <title>{postingId+ ' - '+ postingTitle + ' | Québec Emploi'}</title>
               </Helmet>
            </Grid>
            <Grid item xs={1} align="right" className={"padding"}>
              <IconButton
                edge="start"
                align="right"
                color="inherit"
                aria-label="Close"
                style={{ padding: 8 }}
                onClick={handleClose}
              >
                <CloseIcon className="closeButton"/>
              </IconButton>

            </Grid>
            <Grid item xs={12}>
                <PostDetail postingIdHome={postingId} setInfoModalDetail={setInfoModalDetail} />
             
            </Grid>

          </Grid>

        </DialogContent>
      </Dialog> )
  );
};
export default ModalPostDetail;