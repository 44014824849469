import React, { Fragment, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { SvgIcon } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import Title from '../Utils/Title';


import { useTranslation } from "react-i18next";


function JoinUs() {
    const { t, i18n } = useTranslation('common');
    
    return (
        <Fragment>
            <Header />

            <div id="main">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 ">
                                <Title niveau="1" libelle={t('info.joinUs.title')} />
                                <p>{t('info.joinUs.description')}</p>
                                <Title niveau="2" libelle={t('info.joinUs.phone.title')} />
                                <p><SvgIcon component={PhoneIcon} inheritViewBox /> {t('info.joinUs.phone.description')}</p>
                                <Title niveau="2" libelle={t('info.joinUs.email.title')} />
                                <p><SvgIcon component={EmailIcon} inheritViewBox /> <a href={"mailto:"+t('info.joinUs.email.description')} >{t('info.joinUs.email.description')}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </Fragment>
    );

}
export default JoinUs;