import React, { Fragment, useState,useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';

import Title from '../Utils/Title';

//import conditionsUtilisation from "../../../public/conditions-utilisations.js"
import { useTranslation } from "react-i18next";
function loadScript(url, callback)
{
    // Adding the script tag to the head as suggested before
    var head = document.head;
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onreadystatechange = callback;
    script.onload = callback;

    // Fire the loading
    head.appendChild(script);
}

function TermsOfUse() {
    const { t, i18n } = useTranslation('common');
    const [termOfUse,setTermOfUse]=useState();
    useEffect(() => {
        
        loadScript(i18n.resolvedLanguage=='fr'?"../conditions-utilisations.js":"../terms-of-use.js", function() {
            setTermOfUse(condition_src);
        });
        
    }, [i18n.resolvedLanguage])
    return (
        <Fragment>
            <Header />

            <div id="main">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 ">
                                <Title niveau="1" libelle={t('info.termsOfUse.title')} />
                                <div dangerouslySetInnerHTML={{ __html: termOfUse}} ></div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </Fragment>
    );

}
export default TermsOfUse;