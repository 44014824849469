import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import GoogleMapReact from 'google-map-react';
import useSupercluster from "use-supercluster";

import { useTheme } from '@mui/material/styles';

import Marker from './Marker';
import '../../css/map.css';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;
function setMapFocus(googleApiObj,listePoint){
    if (googleApiObj){     
      const {
        map,
        maps
      } = googleApiObj;
      const bounds = new maps.LatLngBounds()
      
      for (const [key, props] of Object.entries(listePoint)) {
        bounds.extend(new maps.LatLng(
          parseFloat(props[0].latitude),
          parseFloat(props[0].longitude)));
      }
      
      map.fitBounds(bounds);
  }
}
const MarkerCluster = ({ children }) => children;

const MainMapBlock = ({ t, filterParent, handleOnClickConsult, listePoint }) => {
  const location = useLocation();
  const [showMapInfo,setShowMapInfo] = useState(null);
  // const positionDefault = [52.35, -69.57];
  const [localisation, setLocalisation] = useState(filterParent.localisation);
  const theme = useTheme();
  const [positionDefault, setPositionDefault] = useState([46.828254, -71.323337]);
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null);

  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);

  const [circle, setCircle] = useState(undefined)

  // Cluster value for job list
  const points = [];
  Object.keys(listePoint).map(point =>
    listePoint[point].map((result) => (
      points.push({
        type: "Feature",
        properties: { cluster: false, jobId: result.ide_affch, place: result, placeArray: listePoint[point] },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(result.longitude),
            parseFloat(result.latitude)
          ]
        }
      })
    ))
  );
  // Creating clusters using the hook useSupercluster
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 12 }
  });

  useEffect(() => {

    if (googleApiObj) {
      const {
        map,
        maps
      } = googleApiObj;
      renderGeoFences(map, maps)
    }
  }, [googleApiObj, localisation])

  function renderGeoFences(map, maps) {
    if (circle) circle.setMap(null);
    setCircle(new maps.Circle({
      strokeColor: theme.palette.pelDarkBlue,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: theme.palette.pelBlue,
      fillOpacity: 0.3,
      map,
      center: { lat: parseFloat(localisation.latitude), lng: parseFloat(localisation.longitude) },
      radius: localisation.distance * 1000,
      visible: localisation.latitude !== "",
    }))
  }

  useEffect(() => {
    setLocalisation(filterParent.localisation);
    if (localisation.latitude){
      setPositionDefault([localisation.latitude, localisation.longitude]);
      
    }
  }, [listePoint, filterParent])

  useEffect(() => {
    setLocalisation(filterParent.localisation);
    setMapFocus(googleApiObj,listePoint);
    
  }, [googleApiObj,listePoint, filterParent])
  function toggleShowMapInfo(ideAffch){
    setShowMapInfo(ideAffch);
  }
  return (
    listePoint || listePoint.length > 0 ?
      <Wrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          defaultZoom={10}
          defaultCenter={[46.828254, -71.323337]}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={
            ({ map, maps }) => {
              mapRef.current = map;
              setIsGoogleApiLoadedObj({
                map,
                maps
              });
              setMapFocus(googleApiObj,listePoint);
            }
          }
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat
            ]);
          }}
        >

          {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
              place: place,
              placeArray: placeArray
            } = cluster.properties;

            if (isCluster) {
              return (
                <MarkerCluster
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className="cluster-marker"
                    // Cluster diameter style
                    style={{
                      width: `${40 + (pointCount / points.length) * 100}px`,
                      height: `${40 + (pointCount / points.length) * 100}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </MarkerCluster>
              );
            }

            return (
              <Marker
                key={`job-${cluster.properties.jobId}`}
                lat={latitude}
                lng={longitude}
                show={showMapInfo}
                place={place}
                placeArray={placeArray}
                toggleShow={toggleShowMapInfo}
                t={t}
                handleOnClickConsult={handleOnClickConsult}
                mapRef={mapRef}
              >
              </Marker>
            );
          })}

        </GoogleMapReact>
      </Wrapper >
      :
      <></>
  )
}

export default MainMapBlock