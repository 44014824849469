import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';
import Info from './Info';
import Recommend from './Recommend';
import SkeletonPosteDetail from '../skeleton/SkeletonPosteDetail'

import { sendEventPosting} from '../../js/OutilRecommandation';
import { SvgIcon } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import Title from '../Utils/Title';
import Breadcrumb from '../Utils/Breadcrumb';

import '../../css/postDetail.css';
import { useTranslation } from "react-i18next";
import { PelButton } from '../../themes/@pel/components';
import { Helmet } from 'react-helmet';
import Alert from '@mui/material/Alert';

import { getUserIA } from '../../js/OutilRecommandation';
import {
    getManitouLsUrl,
    getRecommandationsUrl,
    getLibreServiceUrl
} from '../../js/utils';

function PostDetail({postingIdHome,setInfoModalDetail,mainRef}) {
    const { t, i18n } = useTranslation('common');
    let { postingIdParam } = useParams();
    let postingId= postingIdHome||postingIdParam;

    const [post, setPost] = useState({});
    const [recommend, setRecommend] = useState([]);
    const [loading, setLoading] = useState(true);
    const [recommendShow, setRecommendShow] = useState(false);

    useEffect(() => {
        
        setLoading(true);
        fetch(getManitouLsUrl() + '/manitouLS/cache/postingJsonCS/' + postingId + '/' + i18n.resolvedLanguage)
            .then((response) => {    
                if (response.ok) {      
                    return response.json();    
                }                   
                throw new Error(`HTTP Response Code: ${response?.status}`);  
            })  
            .then((res) => {
                setPost(res);
                fetch(getManitouLsUrl() + '/manitouLS/nocache/postingLogShow/' + postingId + '/HS')
               
                setLoading(false);
            })
            .catch((err) => {
                setPost({error:true});
                console.log(err.message);
            });
    }, [postingId, i18n.resolvedLanguage])
    useEffect(() => {
        setRecommendShow(false);
        fetch(getRecommandationsUrl() + '/recommandations/recommend?id_candidat='+getUserIA()+'&nombre_souhaite=10')
            .then((response) => {    
                if (response.ok) {      
                    return response.json();    
                }                   
                throw new Error(`HTTP Response Code: ${response?.status}`);  
            })  
            .then((res) => {
                let data = [];
                if (!res.par_defaut) {
                    data = res.postes_recommandes;
                    data = data.filter(function (item) {
                        return item !== postingId
                    });
                }
                setRecommend(data);
            })
            .catch((err) => {
                
                console.log(err.message);
            });
    }, [postingId])
    const handleOnClickPostuler = (ideAffch) => {
        sendEventPosting('POSTING_APPLY',ideAffch);
        window.location.href = (getLibreServiceUrl() + '/libreservice/?ide_poste=' + ideAffch);
    }
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    useEffect(() => {
        if(post.title){
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', 'UA-167288201-1');
        }
        document.querySelectorAll('script[type="application/ld+json"]').forEach(e => e.remove());

        if(post.googleForJob){
            document.head.insertAdjacentHTML('beforeend',post.googleForJob.key);
        }
    }, [post])
    
    const dataBreadcrumb = [{ "title": t('home.title'), "link": "/" }, { "title": t('postDetail.title') }];
    return (
        <Fragment>
            {(postingIdParam?
            (<Fragment>
                <Helmet>
                    <title>{postingId + (!loading?' - ' + post.title:'')}  | Québec Emploi</title>
                </Helmet>
                <Header/>
            </Fragment>):'')}
            <div id="main">
                 {(postingIdParam?
                <Breadcrumb data={dataBreadcrumb} />:'')}
                <div className="container-fluid">
                    <div className="container">
                        {(postingIdParam?
                            <div className="row">
                                <div className="col-12 col-md-12 ">
                                    <Title niveau="1" libelle={postingId + (!loading?' - ' + post.title:'')} />
                                </div>
                            </div>:'')}
                        {(loading && !post.error?<SkeletonPosteDetail></SkeletonPosteDetail>:
                        (post.error?<Alert severity="warning">{t('postDetail.notFound')}</Alert>:
                        <Fragment>
                            <div className="row">
                                <div className="col-10 col-md-10 mediaLink">
                                    <a href={post.facebookSharingURL} title={t('postDetail.facebookLink')}><span><SvgIcon component={FacebookIcon} inheritViewBox /></span></a>
                                    <a href={post.twitterSharingURL} title={t('postDetail.twitterLink')}><span><SvgIcon component={TwitterIcon} inheritViewBox /></span></a>
                                    <a href={post.linkedInSharingUrl} title={t('postDetail.linkedInLink')}><span><SvgIcon component={LinkedInIcon} inheritViewBox /></span></a>
                                    <a href={'mailto:?subject=Emploi offert&body=' + window.location+'/poste/'+postingId} title={t('postDetail.emailLink')}><span><SvgIcon component={EmailIcon} inheritViewBox /></span></a>
                                </div>
                                <div className="col-2 col-md-2 w100">

                                    <PelButton type="secondary" variant="outlined" onClick={() => window.print()}>
                                        {t('postDetail.print')}
                                    </PelButton>


                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-6 ">
                                    <div className="infoSection">
                                        <Info post={post} idSection="49" />{/*Employeur  */}
                                        <Info post={post} idSection="50" />{/*Site Web  */}
                                        <Info post={post} idSection="34" />{/*Lieu de travail    */}
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 ">
                                    <div className="infoSection">
                                        <Info post={post} idSection="44" />{/*Appelation Emploi  */}
                                        <Info post={post} idSection="54" />{/*Nombre de poste  */}
                                        <Info post={post} idSection="61" />{/*Personne a contacter    */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12 ">
                                    <Info post={post} idSection="51" />{/*Description de l'entreprise  */}
                                    <Info post={post} idSection="46" />{/*Description de l'offre   */}
                                    <Info post={post} idSection="47" />{/*Qualification requise   */}
                                    <Info post={post} idSection="40" />{/*Langue   */}
                                    <Info post={post} idSection="39" />{/*Formations  */}
                                    <Info post={post} idSection="62" />{/*Expérience   */}
                                    <Info post={post} idSection="66" />{/*Copetence   */}
                                    <Info post={post} idSection="68" />{/*Emploi pour ressortissant Ukraniens  */}
                                    <Info post={post} idSection="90" />{/*Emploi pour employés expérimenté  */}
                                    <Info post={post} idSection="69" />{/*Emploi subventionneé  */}
                                    <Info post={post} idSection="70" />{/*Emploi avec possibilité de formation au milieu de travail  */}
                                    <Info post={post} idSection="52" />{/*Experience de travail   */}


                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-12 ">
                                    <div className="infoSection">

                                        <div className="row">

                                            <div className="col-3 col-md-3">
                                                <Info post={post} idSection="42" />{/*Nombre d'heure   */}
                                                <Info post={post} idSection="37" />{/*Type de l'emploi  */}
                                                <Info post={post} idSection="63" />{/*Salaire minimal  */}
                                            </div>

                                            <div className="col-3 col-md-3">
                                                <Info post={post} idSection="67" />{/*Type de l'offre   */}
                                                <Info post={post} idSection="56" />{/*Horaire */}
                                                <Info post={post} idSection="64" />{/*Salaire maximal */}
                                            </div>

                                            <div className="col-3 col-md-3">
                                                <Info post={post} idSection="57" />{/*Date debut de l'emploi   */}
                                                <Info post={post} idSection="55" />{/*Disponibilite */}
                                                <Info post={post} idSection="65" />{/*Base salarial   */}
                                            </div>

                                            <div className="col-3 col-md-3">
                                                <Info post={post} idSection="58" />{/*Date fin de l'emploi   */}
                                                <Info post={post} idSection="60" />{/*Duree d'emploi     */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-6 ">
                                    <Info post={post} idSection="59" />{/*Publié le  */}
                                </div>
                                {(post.applicationURL != '' ?
                                    <div className="col-6 col-md-6 w100">

                                        <PelButton type="primary" variant="outlined" onClick={() => openInNewTab(post.applicationURL)}>
                                            {t('postDetail.applyEmployer')}
                                        </PelButton>
                                    </div> : '')}
                            </div>
                            {(post.applicationURL === '' ?
                            <div className="row">
                                <div className="col-12 col-md-12 w100">

                                    <PelButton type="secondary" variant="outlined" onClick={() => handleOnClickPostuler(postingId)}>
                                        {t('postDetail.applyQE')}
                                    </PelButton>
                                </div>
                            </div>:'')}
                        </Fragment>))}
                        {(recommend.length > 0 ?
                            <div className="row">
                                <div className="col-12 col-md-12 w100">
                                {(recommendShow?
                                <Title niveau="2" libelle="Postes qui pourraient vous intéresser" />:'')}
                                    {recommend.map((c,id) =>
                                        (c!=postingId ?
                                        <Recommend key={id} postingId={c} setInfoModalDetail={setInfoModalDetail} recommendShow={recommendShow} setRecommendShow={setRecommendShow} />
                                        :''))}

                                </div>
                            </div> : '')}
                    </div>
                </div>
            </div>


            {(postingIdParam?
             <Footer />:'')}
        </Fragment>
    );

}
export default PostDetail;