import { createTheme } from "@mui/material";


export const theme = createTheme({
    typography: {
        button: {
            fontSize: 16,
            fontWeight: "bold"
        }
    },
    palette: {
        pelBlue: '#095797',
        pelDarkBlue: '#223654',
        // Buttons
        primary: {
            main: '#223654'
        },
        secondary: {
            main: '#095797',
            hover: "rgba(9, 87, 151, 0.16)",
            focus: "#223654",
            shadowFocus: "#4a98d9",
            active: "rgba(9, 87, 151, 0.08)"
        },
        tertiary: {},
        // Checkboxes
        checkbox: {
            active: "#808a9d",
            disabled: "#bfc4ce",
            focus: "#5b6476",
            pressed: "#1479cc",
            checked: "#1479cc"
        },
        chip: {
            background: "#DAE6F0",
            hover: "#6991D1",
            active: "#6991D1",
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 112,
                    minHeight: 56,
                },
            },
        },
    },
});