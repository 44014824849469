import 'bootstrap/dist/css/bootstrap.min.css';
import RoutesApp from './components/routes';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './themes/theme';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <ThemeProvider theme={theme} >
      
      <CssBaseline />
      <div className="App">
        <BrowserRouter basename="/plateforme-emploi">
             <ScrollToTop/>
            <RoutesApp />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
