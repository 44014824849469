import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import common_fr from "./translation/fr/common.json";
import common_en from "./translation/en/common.json";

i18next
    .use(I18NextHttpBackend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)                      // Pass i18n from component to component
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'fr',                              // language to use
        resources: {
            en: {
                common: common_en               // 'common' is our custom namespace
            },
            fr: {
                common: common_fr
            },
        },
    });

export default i18next;