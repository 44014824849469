import Select, { components } from 'react-select'
import React, { Fragment, useEffect, useState } from 'react';

import DOMPurify from "dompurify";
import Title from '../Utils/Title';
import '../../css/modal.css';

import { Dialog, DialogContent, FormControl, FormControlLabel, Grid, IconButton ,Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PelButton, PelInputLabel } from '../../themes/@pel/components';
import { PelCheckBox } from '../../themes/@pel/checkbox';
import SkeletonModalFilter from '../skeleton/SkeletonModalFilter'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused?'#dfe6ed' :isSelected?'#dae6f0':'#fff',
      color: '#095797',
      cursor: 'default'
    };
  },
  multiValue: (styles, { data }) => {
    const color = '#dae6f0';
    return {
      ...styles,
      backgroundColor: color,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }), 
  menu: (styles, { data }) => ({
    ...styles,
    zIndex:2,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      border: '1px solid #6991d1'
    },
  }),
};
const ModalFilter = ({ t,
  handleClose,
  show,
  filterParent,
  setFilterParent,
  filterOption }) => {

  const [filter, setFilter] = useState(filterParent);
  const [studyOptions, setStudyOptions] = useState([]);
  const [showMore,setShowMore]= useState(false);
  const applyFilterModal = () => {
    setFilterParent(filter);
    handleClose();
  }
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="noOption">{t("modal.noOption")}</span>
      </components.NoOptionsMessage>
    );
  };

  useEffect(() => {
    setFilter(filterParent);
  }, [filterParent])


  useEffect(() => {
    // Display study level children
    let results = [];
    filter.levelEducation.forEach((level) => {
      results.push({
        label: filterOption.levelEducation.find(lev => lev.value === level).label,
        options:
          filterOption.studyDiscipline.filter(studyDis => studyDis.levelEducation === level).map(study => {
            return {
              label: study.label,
              value: study.value
            }
          })
      })
    })

    setStudyOptions(results)
  }, [filter])

  const sanitizedHtmlContent = DOMPurify.sanitize( t("modal.experimente"));
  return (

      <Dialog
        className={"root"}
        maxWidth="md"
        open={show}
        disableEscapeKeyDown
        onClose={() => handleClose()}
      >
        <DialogContent className={"paddingModal"} 
                style={{ paddingTop: 0,paddingBottom:0,paddingLeft:28,paddingRight:28 }}>
          <Grid container spacing={1} className="fixedTitle">
            <Grid item xs={11} align="left" className={"padding"}>


              <Title niveau="4" libelle={t("modal.title")} />
            </Grid>
            <Grid item xs={1} align="right" className={"padding"}>
              <IconButton
                edge="start"
                align="right"
                color="inherit"
                aria-label="Fermer"
                style={{ padding: 0 }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>

            </Grid>
          </Grid>
          <Grid container spacing={1}>

          {(filterOption.administrativeRegion ?
            <Grid item xs={12}>

              <Grid container direction="row" className={"mainHeader"}>
                <Grid item xs={12}>
                  
                <FormControl fullWidth>
                    <PelInputLabel variant="standard" htmlFor="since">
                      {t("modal.since")}
                    </PelInputLabel>
                    <Select
                      closeMenuOnSelect={true}
                      placeholder={"- " + t("modal.allPositions") + " -"}
                      name="since"
                      id="since"
                      aria-label={t("modal.since")}
                      options={filterOption.postedSince}
                      components={{ NoOptionsMessage }}
                      styles={colourStyles}
                      value={filterOption.postedSince.filter((c) => filter.postedSince == c.value)}
                      onChange={(choice) => {
                        const since = choice.value;
                        setFilter({ ...filter, postedSince: since });
                      }
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <PelInputLabel variant="standard" htmlFor="administrativeRegion">
                      {t("modal.administrativeRegion")}
                    </PelInputLabel>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,NoOptionsMessage
                      }}
                      placeholder={"- " + t("modal.allRegion") + " -"}
                      name="administrativeRegion"
                      id="administrativeRegion"
                      aria-label={t("modal.administrativeRegion")}
                      styles={colourStyles}
                      options={filterOption.administrativeRegion}
                      value={filterOption.administrativeRegion.filter((c) => filter.adminRegion.includes(c.value))}
                      onChange={(choice) => {
                        const administrativeRegion = choice.map(a => a.value);
                        setFilter({ ...filter, adminRegion: administrativeRegion });
                      }
                      }
                    />
                  </FormControl>


                  <FormControl fullWidth>
                    <PelInputLabel variant="standard" htmlFor="offerType">
                      {t("modal.offerType")}
                    </PelInputLabel>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,NoOptionsMessage
                      }}
                      placeholder={"- " + t("modal.allOfferType") + " -"}
                      name="offerType"
                      id="offerType"
                      styles={colourStyles}
                      options={filterOption.offerType}
                      aria-label={t("modal.offerType")}
                      value={filterOption.offerType.filter((c) => filter.offerType.includes(c.value))}
                      onChange={(choice) => {
                        const offerType = choice.map(a => a.value);
                        setFilter({ ...filter, offerType: offerType });
                      }
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <PelInputLabel variant="standard" htmlFor="jobType">
                      {t("modal.jobType")}
                    </PelInputLabel>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,NoOptionsMessage
                      }}
                      placeholder={"- " + t("modal.allJobType") + " -"}
                      name="jobType"
                      id="jobType"
                      styles={colourStyles}
                      options={filterOption.commitment}
                      aria-label={t("modal.jobType")}
                      value={filterOption.commitment.filter((c) => filter.commitment.includes(c.value))}
                      onChange={(choice) => {
                        const commitment = choice.map(a => a.value);
                        setFilter({ ...filter, commitment });
                      }
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <PelInputLabel variant="standard" htmlFor="duration">
                      {t("modal.duration")}
                    </PelInputLabel>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,NoOptionsMessage
                      }}
                      placeholder={"- " + t("modal.allDuration") + " -"}
                      name="duration"
                      id="duration"
                      styles={colourStyles}
                      options={filterOption.typePoste}
                      aria-label={t("modal.duration")}
                      value={filterOption.typePoste.filter((c) => filter.jobDuration.includes(c.value))}
                      onChange={(choice) => {
                        const jobDuration = choice.map(a => a.value);
                        setFilter({ ...filter, jobDuration });
                      }
                      }
                    />
                  </FormControl>


                  <FormControlLabel
                    control={
                      <PelCheckBox
                        checked={filter.excludeAgencies}
                        onChange={(event) => {
                          const excludeAgencies = event.target.checked;
                          setFilter({ ...filter, excludeAgencies: excludeAgencies });
                        }}
                        name="agency" />
                    }
                    label={t("modal.agency")}
                  />

                  <FormControlLabel
                    control={
                      <PelCheckBox
                        checked={filter.isUkrainian}
                        onChange={(event) => {
                          const isUkrainian = event.target.checked;
                          setFilter({ ...filter, isUkrainian: isUkrainian });
                        }}
                        name="ukraine" />
                    }
                    label={t("modal.ukraine")}
                  />
                  <FormControlLabel
                    control={
                      <PelCheckBox
                        checked={filter.isExperimente}
                        onChange={(event) => {
                          const isExperimente = event.target.checked;
                          setFilter({ ...filter, isExperimente: isExperimente });
                        }}
                        name="experimente" />
                    }
                    
                    label={  
                      <Typography
                        dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
                      />
                    }
                  />

{/*
                  <FormControlLabel
                    control={
                      <PelCheckBox
                        checked={filter.isTrainingProgram}
                        onChange={(event) => {
                          const isTrainingProgram = event.target.checked;
                          setFilter({ ...filter, isTrainingProgram: isTrainingProgram });
                        }}
                        name="trainingProgram" />
                    }
                    label={t("modal.trainingProgram")}
                  />*/}
                  {(!showMore?
                   <div style={{textAlign:'right',marginTop:15}}><a href="#"  onClick={()=>setShowMore(true)} style={{marginTop:10}} >{t("modal.showMore")} <ExpandMoreIcon/></a></div>:'')}
                  {(showMore?
                  <Fragment>
                    <FormControl fullWidth>
                      <PelInputLabel variant="standard" htmlFor="level">
                        {t("modal.level")}
                      </PelInputLabel>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,NoOptionsMessage
                        }}
                        placeholder={"- " + t("modal.allLevel") + " -"}
                        name="level"
                        id="level"
                        styles={colourStyles}
                        options={filterOption.levelEducation}
                        aria-label={t("modal.level")}
                        value={filterOption.levelEducation.filter((c) => filter.levelEducation.includes(c.value))}
                        onChange={(choice) => {
                          const levelEducation = choice.map(a => a.value);
                          setFilter({ ...filter, levelEducation });
                        }
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <PelInputLabel variant="standard" htmlFor="field">
                        {t("modal.field")}
                      </PelInputLabel>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,NoOptionsMessage
                        }}
                        placeholder={"- " + t("modal.allField") + " -"}
                        name="field"
                        id="field"
                        styles={colourStyles}
                        options={studyOptions}
                        aria-label={t("modal.field")}
                        value={filterOption.studyDiscipline.filter((c) => filter.studyDiscipline.includes(c.value))}
                        onChange={(choice) => {
                          const studyDiscipline = choice.map(a => a.value);
                          setFilter({ ...filter, studyDiscipline });
                        }
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <PelInputLabel variant="standard" htmlFor="mrc">
                        {t("modal.MRC")}
                      </PelInputLabel>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,NoOptionsMessage
                        }}
                        placeholder={"- " + t("modal.allMRC") + " -"}
                        name="mrc"
                        id="mrc"
                        styles={colourStyles}
                        options={filterOption.MRC}
                        aria-label={t("modal.MRC")}
                        value={filterOption.MRC.filter((c) => filter.mrc.includes(c.value))}
                        onChange={(choice) => {
                          const MRC = choice.map(a => a.value);
                          setFilter({ ...filter, mrc: MRC });
                        }
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <PelInputLabel variant="standard" htmlFor="bsq">
                        {t("modal.BSQ")}
                      </PelInputLabel>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,NoOptionsMessage
                        }}
                        placeholder={"- " + t("modal.allBSQ") + " -"}
                        name="bsq"
                        id="bsq"
                        styles={colourStyles}
                        options={filterOption.BSQ}
                        aria-label={t("modal.BSQ")}
                        value={filterOption.BSQ.filter((c) => filter.bsq.includes(c.value))}
                        onChange={(choice) => {
                          const BSQ = choice.map(a => a.value);
                          setFilter({ ...filter, bsq: BSQ });
                        }
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <PelInputLabel variant="standard" htmlFor="sector">
                        {t("modal.sector")}
                      </PelInputLabel>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,NoOptionsMessage
                        }}
                        placeholder={"- " + t("modal.allSector") + " -"}
                        name="sector"
                        id="sector"
                        styles={colourStyles}
                        options={filterOption.SCIAN}
                        aria-label={t("modal.sector")}
                        value={filterOption.SCIAN.filter((c) => filter.scian.includes(c.value))}
                        onChange={(choice) => {
                          const scian = choice.map(a => a.value);
                          setFilter({ ...filter, scian });
                        }
                        }
                      />
                    </FormControl>
                    <div style={{textAlign:'right',marginTop:15}}><a href="#"  onClick={()=>setShowMore(false)}  style={{marginTop:10}} >{t("modal.showLess")}  <ExpandLessIcon/></a></div>
                  </Fragment>:'')}
                </Grid>
              </Grid>

            </Grid>
            :<SkeletonModalFilter/>)}
          </Grid>
          <Grid container spacing={1} className="fixedButton">
            <Grid item xs={12} sm={3} lg={3}>
              <PelButton type="secondary" variant="outlined" onClick={handleClose} fullWidth>
                {t("cancel")}
              </PelButton>
            </Grid>
            <Grid item xs={12} sm={7} lg={7} className={"buttonApply"}>
              <PelButton type="contained" onClick={() => applyFilterModal()} fullWidth>
                {t("filter.apply")}
              </PelButton>
            </Grid>

          </Grid>

        </DialogContent>
      </Dialog> )
};
export default ModalFilter;