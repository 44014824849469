import { alpha, styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import Slider from '@mui/material/Slider';
import { Chip, InputLabel, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// The Components from Material UI are customized here to match to the PEL Design


// Pel Button
export const PelButton = styled(MuiButton)(({ theme, type }) => {
    switch (type) {
        case "secondary":
            return {
                color: theme.palette.secondary.main,
                backgroundColor: "#fff",
                border: "2px solid " + theme.palette.secondary.main,
                borderRadius: "inherit",
                "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                },
                "&:focus": {
                    // backgroundColor: theme.palette.secondary.focus,
                    borderColor: theme.palette.secondary.focus,
                    boxShadow: "0 0 0 2px " + theme.palette.secondary.shadowFocus,
                },
                "&:active": {
                    backgroundColor: theme.palette.secondary.active,
                }
            }
        case "compact":
            return {
                minWidth: 80,
                minHeight: 40,
                backgroundColor: theme.palette.pelDarkBlue,
                border: "2px solid #095797",
                borderRadius: "inherit",
                border: "2px solid #095797",
                color: "#fff",
                "&:hover": {
                    backgroundColor: theme.palette.pelBlue,
                    color: "#fff",
                }
            }
        default:
            return {
                backgroundColor: theme.palette.pelDarkBlue,
                border: "2px solid #095797",
                borderRadius: "inherit",
                border: "2px solid #095797",
                color: "#fff",
                "&:hover": {
                    backgroundColor: theme.palette.pelBlue,
                    color: "#fff",
                }
            }
    }
}
);

// PEL Slider
export const PelSlider = styled(Slider)(({ theme }) => ({
    minWidth: 210,
    color: theme.palette.pelBlue,
    '& .MuiSlider-thumb': {
        '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.pelDarkBlue, 0.16)}`,
        },
        '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.pelDarkBlue, 0.16)}`,
        },
    },
}));


// PEL Input Label
export const PelInputLabel = styled(InputLabel)(({ theme }) => ({
    display: "contents",
    color: theme.palette.pelDarkBlue
}));

// PEL TextField
export const PelTextField = styled(TextField)(({ theme }) => ({
    borderRadius: "inherit",
    color: theme.palette.pelBlue,
    '& label.Mui-focused': {
        color: theme.palette.pelBlue,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.pelBlue,
    },
    '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //     borderColor: theme.palette.pelBlue,
        // },
        '&:hover fieldset': {
            borderColor: theme.palette.pelBlue,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.pelBlue,
        },
    },
}));


// PEL Chip
const CustomizedChip = styled(Chip)(({ theme }) => ({
    background: theme.palette.chip.background,
    color: theme.palette.pelBlue,
    borderRadius: "inherit",
    margin: "8px !important",
    marginLeft: "0px !important",
    '&:hover, &.Mui-focusVisible': {
        border: `1px solid ${theme.palette.chip.hover}`,
    },
    '&.Mui-active': {
        border: `1px solid ${theme.palette.chip.active}`,
    },
}));
const Icons = (props) => (
    <div>
        <CloseIcon className="MuiChip-icon MuiChip-deleteIcon" {...props}/>
    </div>
);
export const PelChip = (props) => {
    return (
        <CustomizedChip
            deleteIcon={<Icons {...props.onDelete} />}
            {...props}
        />
    );
}