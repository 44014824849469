import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const SkeletonListePoste = () => {
    return (Array(25)
            .fill()
            .map((item, index) => (
              <tr key={index}>
                <td>
                   <Skeleton height={25} width={600} count={1} />
                </td><td>
                   <Skeleton height={25} width={250} count={1} />
                </td><td>
                   <Skeleton height={25} width={150} count={1} />
                </td><td>
                   <Skeleton height={25} width={150} count={1} />
                </td><td>
                   <Skeleton height={25} width={150} count={1} />
                </td>
               </tr>
            ))
    );
  };
  export default SkeletonListePoste;