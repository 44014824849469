import { useState, useEffect } from 'react';

import '../../css/filterList.css';
import Title from '../Utils/Title';

import { Grid, Stack } from '@mui/material';
import { PelChip } from '../../themes/@pel/components';

const FilterList = ({
    t,
    titre,
    filterParent,
    setFilterParent,
    filterOption }) => {

    // Filter
    const [filter, setFilter] = useState();

    function formatCriteria() {
        if (filterOption.administrativeRegion) {
            var newFilter = {};
            Object.keys(filterParent).forEach(function (key) {
                switch (key) {
                    case "inputSearch":
                        return;
                    case "localisation":
                        return;
                    default:
                        if (typeof filterParent[key] === "object") {
                            const listOption = filterParent[key].map((valueFilter) => {
                                var tempKey = key;
                                switch (key) {
                                    case "adminRegion":
                                        tempKey = "administrativeRegion";
                                        break;
                                    case "jobDuration":
                                        tempKey = "typePoste";
                                        break;
                                    case "mrc":
                                        tempKey = "MRC";
                                        break;
                                    case "bsq":
                                        tempKey = "BSQ";
                                        break;
                                    case "scian":
                                        tempKey = "SCIAN";
                                        break;
                                    default:
                                        break;
                                }
                                return filterOption[tempKey].find((optVal) => optVal.value === valueFilter);
                            })
                            newFilter = { ...newFilter, [key]: listOption };
                        } else {
                            newFilter = { ...newFilter, [key]: filterParent[key] };
                        }
                }
            });
            setFilter(newFilter);
        }
    }

    useEffect(() => {
        formatCriteria()
    }, [filterOption, filterParent])

    function handleDeleteObject(toDelete, filterName) {
        var copy = filterParent;
        const items = filterParent[filterName].filter((obj) => obj !== toDelete.value);
        copy = {
            ...copy,
            [filterName]: items
        }
        setFilterParent(copy);
    }

    function handleDeleteBoolean(filterName) {
        setFilterParent({
            ...filterParent,
            [filterName]: false
        });
    }

    function handleDeleteString(filterName) {
        setFilterParent({
            ...filterParent,
            [filterName]: ""
        });
    }

    return (
        <div className="filterList">

            <Title niveau="4" libelle={titre} />
            <Grid>
                {
                    filter && Object.keys(filter).length > 0 &&
                    < Stack direction="row" spacing={1} className="chipContent">
                        {
                            filter.adminRegion.map((res) => {
                                return <PelChip
                                    key={"ar" + res.value}
                                    label={res.label}
                                    onDelete={() => handleDeleteObject(res, "adminRegion")}
                                />
                            })
                        }
                        {
                            filter.offerType.map((res) => {
                                    return <PelChip
                                        label={res.label}
                                        onDelete={() => handleDeleteObject(res, "offerType")}
                                    />
                                })
                        }
                        {
                            filter.commitment.map((res) => {
                                return <PelChip
                                    key={"com" + res.value}
                                    label={res.label}
                                    onDelete={() => handleDeleteObject(res, "commitment")}
                                />
                            })
                        }
                        {
                            filter.jobDuration.map((res) => {
                                return <PelChip
                                    key={"jd" + res.value}
                                    label={res.label}
                                    onDelete={() => handleDeleteObject(res, "jobDuration")}
                                />
                            })
                        }
                        {
                            filter.levelEducation.map((res) => {
                                return <PelChip
                                    key={"le" + res.value}
                                    label={res.label}
                                    onDelete={() => handleDeleteObject(res, "levelEducation")}
                                />
                            })
                        }
                        {
                            filter.studyDiscipline.map((res) => {
                                return <PelChip
                                    key={"sd" + res.value}
                                    label={res.label}
                                    onDelete={() => handleDeleteObject(res, "studyDiscipline")}
                                />
                            })
                        }
                        {
                            filter.mrc.map((res) => {
                                return <PelChip
                                    key={"msc" + res.value}
                                    label={res.label}
                                    onDelete={() => handleDeleteObject(res, "mrc")}
                                />
                            })
                        }
                        {
                            filter.bsq.map((res) => {
                                return <PelChip
                                    key={"bsq" + res.value}
                                    label={res.label}
                                    onDelete={() => handleDeleteObject(res, "bsq")}
                                />
                            })
                        }
                        {
                            filter.scian.map((res) => {
                                return <PelChip
                                    key={"sc" + res.value}
                                    label={res.label}
                                    onDelete={() => handleDeleteObject(res, "scian")}
                                />
                            })
                        }
                        {
                            filter.postedSince &&
                            <PelChip
                                key={"postedSince"}
                                label={filter.postedSince + " " + (filter.postedSince==1?t("day"):t("days"))}
                                onDelete={() => handleDeleteString("postedSince")}
                            />
                        }
                        {
                            filter.excludeAgencies &&
                            <PelChip
                                key={"excludeAgencies"}
                                label={t("modal.agency")}
                                onDelete={() => handleDeleteBoolean("excludeAgencies")}
                            />
                        }
                    {
                        filter.isUkrainian &&
                        <PelChip
                            key={"isUkrainian"}
                            label={t("modal.ukraine")}
                            onDelete={() => handleDeleteBoolean("isUkrainian")}
                        />
                }
                {
                    filter.isExperimente &&
                    <PelChip
                        key={"isExperimente"}
                        label={t("modal.experimenteShort")}
                        onDelete={() => handleDeleteBoolean("isExperimente")}
                    />
            }
                        {/*
                            filter.isSubsidized &&
                            <PelChip
                                key={"isSubsidized"}
                                label={t("modal.subsidized")}
                                onDelete={() => handleDeleteBoolean("isSubsidized")}
                            />
                */    }
                        {/*
                            filter.isTrainingProgram &&
                            <PelChip
                                key={"isTrainingProgram"}
                                label={t("modal.trainingProgram")}
                                onDelete={() => handleDeleteBoolean("isTrainingProgram")}
                            />
            */ }
                    </Stack>
                }
            </Grid>

        </div >
    );
}
export default FilterList;