import React, { useState,useEffect,useCallback } from 'react';
import { useTranslation } from "react-i18next";

import { PelButton } from '../../themes/@pel/components';


import '../../css/recommend.css';

import { sendEventPosting} from '../../js/OutilRecommandation';

import {
    getManitouLsUrl
} from '../../js/utils';

function Recommend({ postingId,setInfoModalDetail,recommendShow,setRecommendShow}) {


    const handleOnClickConsult = useCallback((ideAffch,title) => {
        sendEventPosting('POSTING_RECOMMENDATION_DETAIL',ideAffch);
        setInfoModalDetail({ show:true,postingId:ideAffch,title:title})  
        document.getElementsByClassName('MuiDialogContent-root')[0].scrollTop=0;
    });
    const { t, i18n } = useTranslation('common');
    const [post,setPost]=useState({});
    useEffect(() => {
        fetch(getManitouLsUrl() + '/manitouLS/cache/aPosting/' + i18n.resolvedLanguage+ '/' + postingId  )
            .then((response) => {    
                if (response.ok) {      
                    return response.json();    
                }                   
                throw new Error(`HTTP Response Code: ${response?.status}`);  
            })  
            .then((res) => {
                setPost(res.items[0]);
                setRecommendShow(true);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [i18n.resolvedLanguage])
    return (
        (post.ide_affch?
        <div className="recommendation">
            <div className="row">
                <div className="col-10 col-md-10 ">
                    <p  className="recommendationTitle"> {post.ide_affch} - {post.titre}</p>
                    <p>{post.employeur}</p>
                    <p>{post.nom_ville}</p>
                </div>
            
                <div className="col-2 col-md-2 ">
                
                    <PelButton type="secondary" variant="outlined" onClick={() => handleOnClickConsult(post.ide_affch,post.titre)}>
                        {t("home.consult")}
                    </PelButton>
                </div>
            </div>
        </div>
        :'')
    );

}
export default Recommend;